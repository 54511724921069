@use "sass:color"
@use "../globals/colors"

.form-input
  display: block
  width: 100%
  border-radius: 3px
  border: 1px solid colors.$gray-light
  -webkit-appearance: none

  &:focus
    transition: all .2s ease-in
    border: 1px solid colors.$blue-light
    background-color: color.adjust(colors.$blue-light, $lightness: 95%)
    outline: none

  @media screen and (max-width: 770px)
    padding: 8px
    font-size: 1em

  @media screen and (min-width: 771px)
    padding: 15px
    font-size: 1.2em

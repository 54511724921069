@use "../globals/colors"

.send
  .card-artboard
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    background-color: colors.$blue-lighter

    .next-step
      width: 170px
      align-self: flex-end

  .link-url
    border: 3px solid colors.$blue


@use "sass:color"
@use "../globals/colors"
@use "../globals/variables"

// Nav on top, artboard below
@mixin nav-main-top
  .nav-main
    width: 100%
    height: 100px
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-end
    box-shadow: 0 3px 3px colors.black(.1)

    .logo-wegotchu
      margin: 10px
      margin-bottom: 5px
      width: 120px
      height: 40px

    & > p
      display: none

    .byline
      width: calc(100% - 130px)
      height: 48px
      margin: 0
      position: absolute
      top: 0
      left: 130px
      display: flex
      align-items: center
      justify-content: flex-end
      font-size: .6em

      span
        display: none

    .logo-andyet
      width: 35px
      height: 18px
      margin: 2px 10px 0 5px
      order: 2

@mixin nav-main-steps-top
  .nav-main-steps
    border-top: 1px solid colors.$gray-light
    border-bottom: 1px solid colors.$gray-light

    a
      display: inline-block
      padding: 15px
      padding-left: 50px
      font-size: 80%
      vertical-align: middle
      flex: 1
      text-decoration: none

      &:before,
      &:after
        width: 30px
        height: 30px
        left: 10px
        display: none

      &:before
        top: 10px
      &:after
        top: 15px

      &.current
        width: calc(100% - 150px)

      &:not(.current)
        width: 50px
        padding-left: 0
        overflow: hidden
        white-space: nowrap
        text-indent: -9999em

        &:before,
        &:after
          text-indent: 0

        &:not(:first-child)
          border-left: 1px solid colors.$gray-light

// Nav on left, artboard right
@mixin nav-main-left
  .nav-main
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    padding: 20px
    padding-bottom: 20px
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    background: colors.white(1)
    box-shadow: 3px 0 3px colors.black(.1)
    text-align: center
    z-index: 4

    .logo-wegotchu
      width: 200px
      height: 50px
      margin-bottom: 30px

    .logo-andyet
      width: 48px
      height: 22px

    & > p
      font-size: 90%
      color: colors.black(.7)

    .helpline
      margin-top: 5px

@mixin nav-main-steps-left
  .nav-main-steps
    width: calc(100% + 40px)
    margin: 20px 0
    border-top: 1px solid colors.$blue-light

    a
      display: block
      padding: 20px
      padding-left: 70px
      border-bottom: 1px solid

      &:before,
      &:after
        width: 40px
        height: 40px
        left: 20px
      &:before
        top: 12px
      &:after
        top: 20px

// Pages that always have top nav, no steps
.container.slim
  @include nav-main-top

  .nav-main
    height: 48px

.container:not(.slim)
  @media screen and (max-width: 770px)
    @include nav-main-top
    @include nav-main-steps-top

  @media screen and (min-width: 771px)
    @include nav-main-left
    @include nav-main-steps-left

  .nav-main-steps
    text-align: left
    counter-reset: step-counter

    a
      counter-increment: step-counter
      position: relative
      color: black
      transition: box-shadow .15s ease-in-out
      text-decoration: none

      &:before,
      &:after
        display: block
        position: absolute

        // counter circle
      &:before
        content: ''
        border-radius: 50%
        z-index: 1

      // counter number
      &:after
        content: counter(step-counter)
        text-align: center
        color: white
        font-weight: variables.$font-weight-bold
        z-index: 2

      // steps you haven't reached yet
      &.inactive
        border-color: colors.$gray-light
        color: color.adjust(colors.$gray-light,$lightness: -10)
        cursor: default
        &:before
          background-color: color.adjust(colors.$gray-light,$lightness: -5)

      // steps you have reached
      &.active
        border-color: colors.$blue-light
        &:not(.current):hover
          background-color: colors.$blue-lighter
          color: colors.$blue-saturated
          box-shadow: inset 0 0 0 35px colors.$blue-light
        &:before
          background-color: colors.$blue

      // current step you are on
      &.current
        background-color: colors.$blue
        cursor: default
        color: white

        &:before
          background-color: white
        &:after
          color: colors.$blue

// global nav styles
.nav-main
  background-color: white
  z-index: 100

  .helpline
    display: block

    a
      display: inline-block
      margin: 0 5px
      color: colors.$gray
      text-decoration: underline

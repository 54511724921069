@use "sass:string"
// Font Weights
$font-weight-light: 300
$font-weight-regular: 400
$font-weight-bold: 700

// Mixins

@function b($val) //b for bracket
  @return string.unquote("[#{$val}]")

@use "../globals/colors"

*, *:before, *:after
  box-sizing: border-box

::selection
  background: colors.$blue
  color: white

img
  max-width: 100%

body
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Helvetica Neue', Arial, sans-serif
  font-size: 112.5%
  line-height: 1.4
  color: colors.$gray-darker

a:not(.button)
  color: colors.$blue

@use "../globals/colors"

.instructions
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  text-align: center

  h2
    margin-bottom: 1em
    font-size: 100%

  p
    margin-bottom: 20px
    font-size: 90%

  .logo
    width: 40px
    height: 40px
    margin-bottom: 20px

  .card
    margin-bottom: 30px

    img
      width: auto
      margin: 0 auto

      @media screen and (max-width: 770px)
        max-height: 25vh
        max-height: calc(var(--vh, 1vh) *25)
        min-height: 100px
      @media screen and (min-width: 771px)
        max-height: 35vh
        max-height: calc(var(--vh, 1vh) * 35)
        min-height: 200px

    .message
      max-width: 300px
      margin: 10px auto 0

  small
    color: colors.$gray

  .link-url
    margin: 10px 0
    display: block
    background-color: white
    border: 1px solid colors.$gray-light
    border-radius: 10px
    word-break: break-all
    cursor: pointer

    @media screen and (max-width: 770px)
      padding: 10px
      font-size: .8em
    @media screen and (min-width: 771px)
      padding: 20px
      font-size: 1.1em

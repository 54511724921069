@use "../globals/colors"

// View + Preview card link
// Sign, Share, and Send views
// Sign view has some additional CSS

.view-card
  display: inline-block
  position: relative

  img
    transition: transform .18s ease-in-out

  span
    width: calc(100% + 16px)
    padding: 5px
    position: absolute
    bottom: 10%
    left: -8px
    background-color: white
    color: colors.$blue
    border: 1px solid colors.$blue
    box-shadow: 0 2px 0 0 colors.$blue
    font-size: .75em

  &.external
    span:after
      content: ' ↗'
      position: relative
      bottom: -2px
      opacity: .85

  &:hover
    img
      transform: scale(1.01) translateY(-5px)


@function white($alpha)
  @return rgba(255,255,255,$alpha)

@function black($alpha)
  @return rgba(0,0,0,$alpha)

//- Blues

$blue-saturated-darker: hsl(195, 100%, 5%) !default
$blue-saturated-dark: hsl(195, 100%, 10%) !default
$blue-saturated: hsl(195, 100%, 14%) !default
$blue-saturated-light: hsl(195,46%, 22%) !default
$blue-saturated-lighter: hsl(195,46%, 42%) !default

$blue: hsl(195, 100%, 46%) !default
$blue-light: hsl(195, 100%, 70%) !default
$blue-lighter: hsl(195, 100%, 95%) !default

//- Monochromatic

$gray-darker: hsl(194, 7%, 16%) !default
$gray-dark: hsl(194, 5%, 33%) !default
$gray: hsl(194, 5%, 54%) !default
$gray-light: hsl(194, 9%, 81%) !default
$gray-lighter: hsl(194,9%, 95%) !default

//- Pinks

$pink: hsl(326, 94%, 48%) !default
$pink-light: hsl(344, 97%, 88%) !default
$pink-lighter: hsl(326, 94%, 96%) !default

//- Greens

$green: hsl(142, 47%, 50%) !default
$green-light: hsl(142, 48%, 85%) !default
$green-lighter: hsl(142, 48%, 94%) !default

//- Reds

$red: hsl(342, 100%, 45%) !default
$red-light: hsl(342, 100%, 89%) !default
$red-lighter: hsl(342, 100%, 95%) !default

//- Orange

$orange: hsl(34, 98%, 48%) !default
$orange-light: hsl(34, 98%, 74%) !default
$orange-lighter: hsl(34, 98%, 92%) !default

//- Yellow
$yellow: hsl(45.6, 100%, 50%) !default
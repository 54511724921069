@use "sass:color"
@use "../globals/colors"

.button
  min-width: 100px
  padding: 10px
  display: inline-block
  background-color: colors.$pink
  border: none
  border-radius: 3px
  font-size: .85em
  text-align: center
  text-decoration: none
  cursor: pointer
  transition: background .2s ease-in-out
  user-select: none
  appearance: none

  &:hover
    background-color: color.adjust(colors.$pink, $lightness: -20%)

  &:disabled
    background-color: colors.$pink-light
    cursor: not-allowed

  &.button-disabled
    cursor: not-allowed

  &.next
    width: 100%
    display: block
    background-color: colors.$blue

    &:hover
      background-color: color.adjust(colors.$blue, $lightness: -20%)

.button,
.button:hover
  color: white

@use "../globals/colors"

// Pages with content
// Help, Privacy, Terms

.secondary
  .content-container
    max-width: 500px
    margin: 0 auto

    @media screen and (max-width: 930px)
      padding-top: 20px

  &.help
    .content-container
      @media screen and (min-width: 931px)
        margin-bottom: 50px
      h2
        font-size: 1.2em

  &.terms,
  &.privacy
    .content-container
      font-size: .85em

    h2
      margin-bottom: 10px
      font-size: 1.1em
      font-weight: bold

  @media screen and (max-width: 770px)
    .nav-main
      height: 48px
      border-bottom: 1px solid colors.$gray-light

    .card-artboard
      height: calc(100vh - 48px)
      height: calc(var(--vh, 1vh) * 100 - 48px)

@use "../globals/colors"

.card-list

  @media screen and (max-width: 770px)
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 20px
    padding-bottom: 20px

  @media screen and (min-width: 771px)
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
    grid-gap: 40px

  .card

    &:before
      margin-bottom: 10px
      display: block
      font-size: .5em
      text-transform: uppercase
      color: colors.$gray-dark

    &.birthday:before
      content: 'Birthday'
    &.thanks:before
      content: 'Thanks'
    &.get-well:before
      content: 'Get Well'
    &.condolences:before
      content: 'Condolences'
    &.encouragement:before
      content: 'Encouragement'
    &.congratulations:before
      content: 'Congratulations'
    &.because:before
      content: 'Just Because'
    &.holiday:before
      content: 'Holiday'
    &.farewell:before
      content: 'Farewell'

    img
      width: 100%
      transition: transform .18s ease-in-out

    a
      display: block

      &:hover
        img
          transform: scale(1.01) translateY(-5px)

@use "../globals/variables"

h1, h2, h3, h4, p,
ul, ul li,
ol, ol li
  margin-bottom: 20px

h1, h2, h3, h4
  line-height: 1.3

h1
  font-size: 1.4em

h2
  font-size: 1.4em

em
  font-style: italic

small
  margin: 5px 0
  display: block
  font-size: .75em

hr
  height: 1px
  margin: 30px 0
  background: black
  border: none

ol
  padding-left: 20px
  list-style: decimal

strong
  font-weight: variables.$font-weight-bold

.center
  text-align: center

@use "../globals/colors"

.view

  @media screen and (min-width: 771px)
    &.container
      display: block

  .card-artboard
    margin-top: -48px
    min-height: calc(var(--vh, 1vh) * 100)
    display: flex
    flex-direction: column
    @media screen and (max-width: 770px)
      padding-top: 65px
    @media screen and (min-width: 771px)
      padding-top: 80px

  // card closed
  //-----------------

  .card-front
    height: 100%
    text-align: center
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between

    h3
      margin-bottom: 15px

    img
      max-height: 80%
      align-self: center
      margin-bottom: 15px
      box-shadow: 0 0 4px colors.black(.2)

    button.next
      width: 140px
      padding-left: 35px
      background-repeat: no-repeat
      background-image: url('../../images/logo-wegotchu-mark-white.svg')
      background-position: 15px center
      background-size: 20px auto

  // card open
  //-----------------

  // card front left
  @mixin card-front-left
    .card-open
      position: relative
      height: 100%
      max-height: 100%

    .card-preview
      width: 120px
      position: absolute
      left: 0
      top: 0

    .card-inside
      width: calc(100% - 150px)
      margin-left: 150px
      margin-bottom: 30px
      min-height: 100%
      height: auto

  // card bottom
  @mixin card-bottom
    .card-open
      height: auto
      display: flex
      flex-direction: column

    .card-inside
      height: auto
      order: 1

    .card-preview
      order: 2
      .button
        width: 120px
        margin: 10px auto 0
      img,
      small
        display: none

  .card-preview
    img
      width: 100%
      margin-bottom: 5px
      box-shadow: 0 0 4px colors.black(.2)

  .card-inside
    background-color: white
    box-shadow: 0 0 4px colors.black(.2)

  .card-message
    text-align: center
    margin: 20px
    margin-top: 0
    border-bottom: 1px dotted colors.$gray-light

    h2
      margin: 0
      padding: 20px

  .card-signatures-list
    column-width: 300px

  .card-signature
    width: 100%
    height: 100%
    padding: 40px 20px
    object-fit: contain
    -webkit-column-break-inside: avoid
    break-inside: avoid

    span
      display: block
      white-space: pre-wrap
      font-size: .9em

    img
      width: 100%
      max-height: 100%
      object-fit: contain

  @media screen and (max-width: 1000px)
    @include card-bottom

  @media screen and (min-width: 1001px)
    @include card-front-left

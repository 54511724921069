@use "../globals/colors"
@use "../globals/variables"

.help

  .help-steps
    padding: 25px 0
    counter-reset: step-counter
    list-style-type: none

    li
      counter-increment: step-counter

      @media screen and (max-width: 930px)
        margin-bottom: 30px
        padding-bottom: 40px
        border-bottom: 1px solid colors.$gray-light

        img
          width: calc(100% - 58px)

      @media screen and (min-width: 931px)
        margin-bottom: 50px
        display: flex
        justify-content: space-between
        align-items: center

        img
          width: 55%

      p
        position: relative
        display: block
        padding-top: 20px
        padding-left: 50px

        &:before,
        &:after
          display: block
          position: absolute
          width: 40px
          height: 40px
          left: 0

          // counter circle
        &:before
          content: ''
          border-radius: 50%
          z-index: 1
          top: 12px
          background-color: colors.$blue

        // counter number
        &:after
          content: counter(step-counter)
          text-align: center
          color: white
          font-weight: variables.$font-weight-bold
          z-index: 2
          top: 20px

    img
      max-width: 1000px
      margin-left: 50px
      border: 1px solid colors.$gray-light
      box-shadow: 0 0 0 4px white

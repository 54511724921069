@use "../globals/colors"

.card
  position: relative

  .message
    margin: 5px 0 0
    font-size: .75em
    font-style: italic
    color: colors.$gray

    &:before
      content: 'Inside: '
      font-style: normal
      color: colors.$gray-dark

  .recipient
    color: colors.$gray-dark
    text-align: center
    font-weight: bold

  h2
    margin-top: 5px
    font-size: .75em
    color: colors.$gray-dark

  img
    margin-bottom: 8px
    display: block
    box-shadow: 0 0 4px colors.black(.2)

  &.sidebar
    h2
      margin: 0 0 8px

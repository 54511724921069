@use "../globals/logos"

.logo
  display: inline-block
  background-repeat: no-repeat
  background-size: contain
  background-position: center center
  text-indent: -9999em

  &.logo-andyet
    background-image: logos.$logo-andyet

  &.logo-wegotchu
    background-image: url('../../images/logo-wegotchu.svg')

  &.logo-wegotchu-mark
    background-image: url('../../images/logo-wegotchu-mark.svg')

*,
*:before,
*:after {
  box-sizing: border-box;
}

::selection {
  background: $blue;
  color: #fff;
}

img {
  max-width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Helvetica Neue", Arial, sans-serif;
  font-size: 112.5%;
  line-height: 1.4;
  color: $gray-darker;
}

a:not(.button) {
  color: $blue;
}

h2,
h3,
h4,
p {
  margin-bottom: 30px;
}
h1,
h2,
h3,
h4 {
  line-height: 1.3;
}

h2 {
  font-size: 1.4em;
}

em {
  font-style: italic;
}

small {
  margin: 5px 0;
  display: block;
  font-size: 0.75em;
}

hr {
  height: 1px;
  margin: 30px 0;
  background: #000;
  border: none;
}

strong {
  font-weight: $font-weight-bold;
}

@use "../globals/colors"

@mixin artboard-top
  .sign-sidebar
    display: grid
    grid-template-columns: 1fr 1fr
    grid-column-gap: 30px
    grid-template-areas: "left right1" "left right2"

    .card
      grid-area: left

    .card img,
    .view-card
        width: 50%

    .sign-controls
      grid-area: right1

    .next-step
      grid-area: right2
      align-self: flex-end

  .card-artboard
    display: grid
    grid-template-rows: 1fr 1fr
  .sign-artboard
    min-height: 200px
  .textarea-container
    width: 92%
    small
      font-size: .6em
  .form-input
    font-size: .8em

@mixin artboard-left

  .card-artboard
    display: grid
    grid-template-columns: 1fr 150px
  .textarea-container
    width: 90%

  .form-input
    font-size: 1em

  .sign-sidebar
    display: flex
    flex-direction: column
    justify-content: space-between

  .sign-controls
    margin: 20px 0

.sign

  @media screen and (max-width: 600px)
    @include artboard-top

  @media screen and (min-width: 601px) and (max-width: 770px)
    @include artboard-left
    .sign-sidebar
      .card img,
      .view-card
        width: 60%

  @media screen and (max-width: 770px)
    .card-artboard
      grid-gap: 20px

  @media screen and (min-width: 771px)
    .card-artboard
      grid-gap: 30px

  @media screen and (min-width: 771px) and (max-width: 930px)
    @include artboard-top

  @media screen and (min-width: 931px)
    @include artboard-left
    .view-card
      width: 100%

.sign-artboard
  display: flex
  justify-content: center
  align-items: center
  background-color: white
  border: 1px solid colors.$gray-light
  box-shadow: 3px 3px 3px colors.black(.1)
  overflow: hidden

  .textarea-container

    small
      margin-bottom: 0
      text-align: center
      color: colors.$gray

  textarea
    resize: vertical

    &:not(:placeholder-shown):not(:focus)
      border-color: transparent
      resize: none

      & + small
        display: none

  .canvas-container
    height: 100%
    width: 100%

// Preview card button
.sign-sidebar

  .view-card
    height: 100%
    position: absolute
    top: 0
    text-align: center
    z-index: 2

    @media screen and (max-width: 770px)
      font-size: .85em

    & + img
      transition: transform .18s ease-in-out
      z-index: 1

    &:hover + img
      transform: scale(1.01) translateY(-3px)

.sign-instructions
  max-width: 330px
  padding: 20px
  border-radius: 5px
  text-align: center

  h2
    margin-bottom: 10px

  p
    margin-bottom: 15px

  small
    margin-bottom: 20px

.sign-controls

  button
    width: 100%
    margin: 0
    padding: 10px
    padding-left: 30px
    display: block
    background-color: white
    background-repeat: no-repeat
    background-size: 20px 20px
    background-position: 5px 50%
    border: 1px solid colors.$gray-darker
    text-align: left
    cursor: pointer

    &:hover
      background-color: colors.$blue-light
    &.active
      background-color: colors.$pink
      color: white
    &:focus
      outline: none
      box-shadow: inset 0 0 0 3px white, inset 0 0 0 4px black
    &.selected
      background-color: colors.$pink-light

    &#type
      background-image: url('../../images/icon-text.svg')
      border-bottom: none
    &#draw
      background-image: url('../../images/icon-brush.svg')
    &#erase-all,
    &#eraser
      background-image: url('../../images/icon-erase.svg')

    &#erase-all
      border-left: none

      &:disabled
        opacity: .5
        color: inherit

        &:hover
          background-color: white
          cursor: default

  .sign-erase
    display: flex

  small
    font-size: .6em
    margin: 2px 0 3px
    text-transform: uppercase

  .sign-colors
    margin: 10px 0
    display: flex
    justify-content: space-around

    button
      width: 24px
      height: 24px
      padding: 0
      display: inline-block
      border-radius: 50%
      border: none
      text-indent: -9999em
      transition: transform .2s ease-in-out

      &#black
        background-color: black
      &#red
        background-color: colors.$red
      &#blue
        background-color: colors.$blue
      &#green
        background-color: colors.$green
      &#pink
        background-color: colors.$pink
      &#yellow
        background-color: colors.$yellow

      &:focus
        outline: none
        box-shadow: 0 0 0 3px white, 0 0 0 4px black

  // "disable" buttons for drawing
  // for when type is selected
  button#type.selected
    & ~ .sign-colors
      opacity: .5
      button
        cursor: default
    & ~ .sign-erase
      button
        cursor: default
        &:hover,
        &.selected
          background-color: white
        &#eraser,
        &#erase-all
          opacity: .5

  // controls for drawing
  // for when draw is selected
  button#draw.selected
    & ~ .sign-colors
      button
        &:not(.selected):hover
          transform: translateY(-2px)
          cursor: pointer
        &.selected
          background-image: url('../../images/icon-check.svg')
          background-repeat: no-repeat
          background-size: 15px 15px
          background-position: center center

.drawing-canvas
  touch-action: none

.pass

  .card-artboard
    display: flex
    flex-direction: column
    justify-content: flex-end

  .next-step
    width: 170px
    align-self: flex-end

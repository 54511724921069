@use "../globals/colors"

@media screen and (max-width: 770px)

  .card-artboard
    height: calc(100vh - 1100px)
    height: calc(var(--vh, 1vh) * 100 - 100px)
    padding: 15px

@media screen and (min-width: 771px)

  .container
    display: grid
    grid-template-columns: 250px 1fr

  .card-artboard
    height: 100vh
    height: calc(var(--vh, 1vh) * 100)
    padding: 30px

.card-artboard
  background-color: colors.$gray-lighter
  overflow-y: scroll
  -webkit-overflow-scrolling: touch

.recipient

  .card-artboard
    display: flex
    flex-direction: column
    justify-content: flex-end
    align-items: center

    .next-step
      width: 170px
      align-self: flex-end

    .instructions
      width: 100%
      max-width: 500px
